import { render, staticRenderFns } from "./phone.vue?vue&type=template&id=1602a9c3&scoped=true&"
import script from "./phone.vue?vue&type=script&lang=js&"
export * from "./phone.vue?vue&type=script&lang=js&"
import style0 from "./phone.vue?vue&type=style&index=0&id=1602a9c3&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1602a9c3",
  null
  
)

export default component.exports